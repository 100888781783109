/**
 * Flip class, which uses flip methodology to animate
 * things like height an width easily.
 * For info on flip: https://medium.com/outsystems-experts/flip-your-60-fps-animations-flip-em-good-372281598865
 */

export class Flip {
    /**
     * constructor
     * @param {object} params
     * @param {HTMLElement} params.elementToFlip
     * @param {string} params.flipProperty
     * @param {Function} params.flipFunction
     * @param {string} params.transitionClass
     * // useful when changed value is max-height, because we can only measure height not max-height
     * @param {string} params.differentMeasuredProperty
     */
    constructor({elementToFlip, flipProperty, flipFunction, transitionClass, differentMeasuredProperty}) {
        this.elementToFlip = elementToFlip;
        this.flipProperty = flipProperty;
        this.flipFunction = flipFunction;
        this.transitionClass = transitionClass;
        this.measuredProperty = differentMeasuredProperty || flipProperty;
        this.flipped = false;
    }

    /**
     * Perform the flip function
     * Used with Flip().play()
     * @public
     */
    play() {
        const currentValue = this.elementToFlip.getBoundingClientRect()[this.measuredProperty];
        this.flipFunction();
        const newValue = this.elementToFlip.getBoundingClientRect()[this.measuredProperty];
        this.elementToFlip.style[this.flipProperty] = `${currentValue}px`;
        this.flipped = !this.flipped;

        requestAnimationFrame(() => {
            requestAnimationFrame(() => {
                this.elementToFlip.classList.add(this.transitionClass);
                this.elementToFlip.style[this.flipProperty] = `${newValue}px`;
            });
        });
    }

    /**
     * add event listeners if needed
     * mostly used for transition end.
     * @public
     * @param {string} property
     * @param {Function} callBack
     */
    addListener(property, callBack) {
        this.elementToFlip.addEventListener(property, () => callBack(this.elementToFlip));
    }
}
